import gql from 'graphql-tag'

export const query = gql`
  query findEmployee($filter: hr_employee_bool_exp, $limit: Int,$order: [hr_employee_order_by!]) {
    records: hr_employee(where: $filter, limit: $limit,order_by: $order)  {
      id
      person {
        id
      }
    }
  }
`
export default query
