
  import { Component, Vue } from 'vue-property-decorator'

@Component
  export default class tokenUser extends Vue {
    // Methods

    mounted () {
      window.addEventListener('keydown', this.handleKeyCombination)
    }

    beforeDestroy () {
      window.removeEventListener('keydown', this.handleKeyCombination)
    }

    private handleKeyCombination (event: KeyboardEvent) {
      // Ctrl + Shift + C
      if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === 't') {
        const token = localStorage.getItem('apollo-token')
        if (token) {
          this.copyToClipboard(token)
        } else {
          console.warn('No token found in localStorage')
        }
      }
    }

    private fallbackCopy (text: string) {
      const textarea = document.createElement('textarea')
      textarea.value = text
      textarea.setAttribute('readonly', '')
      textarea.style.position = 'absolute'
      textarea.style.left = '-9999px'
      document.body.appendChild(textarea)
      textarea.select()
      try {
        document.execCommand('copy')
        console.log('Token copied using fallback')
      } catch (err) {
        console.error('Fallback copy failed:', err)
      }
      document.body.removeChild(textarea)
    }

    private async copyToClipboard (text: string) {
      try {
        if (navigator.clipboard && navigator.clipboard.writeText) {
          await navigator.clipboard.writeText(text)
          console.log('Token copied to clipboard')
        } else {
          this.fallbackCopy(text)
        }
      } catch (err) {
        console.error('Failed to copy token:', err)
      }
    }

  // Getters

  // Watchers
  }
