import { Actions } from '../../interfaces'

import { SaleOrder } from '@/entities/sales'
import create from './create'
import fetch from './fetch'
import fetchLite from './fetchLite'
import update from './update'
import find from './find'
import findLite from './findLite'

export const actions: Actions = {
  create,
  fetch,
  fetchLite,
  update,
  find,
  findLite,
}

export default {
  Model: { SaleOrder },
  actions: { [SaleOrder.name]: actions },
}
