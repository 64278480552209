import { Entity, Icon, Price } from '@/models/interfaces'
import { Employee, Platform } from '@/models/user/interfaces'

export interface PlatformOffer extends Entity {
  platform: Platform
  amount: number
}

export enum AppraisalStatus {
  pending,
  appraised,
  appealed,
  close,
  expired,
  appealAnswered,
  reAppealAnswered,
  inspectionScheduled,
  scheduled,
  noOffer,
  reAppeal
}

export enum AppraisalStatusAlert {
  appealAnsweredWithAgreement,
  appraised,
  close,
  expired
}

export interface AppraisalRecord {
  status: AppraisalStatus
  message?: string
  price?: Price
}

export interface Appraisal extends Entity, AppraisalRecord {
  responsible: Employee
  history?: Array<AppraisalRecord>
  offers: Array<PlatformOffer>
}

export const AppraisalStatusIcons: Record<AppraisalStatus, Icon> = {
  [AppraisalStatus.pending]: {
    name: 'Tasación pendiente',
    icon: 'mdi-alert',
    color: 'yellow',
  },
  [AppraisalStatus.appraised]: {
    name: 'Tasado',
    icon: 'mdi-circle',
    color: 'green',
  },
  [AppraisalStatus.appealed]: {
    name: 'Apelado',
    icon: 'mdi-circle',
    color: 'orange',
  },
  [AppraisalStatus.close]: {
    name: 'Cerrado',
    icon: 'mdi-square',
    color: 'primary-darken3',
  },
  [AppraisalStatus.expired]: {
    name: 'Vencido',
    icon: 'mdi-square',
    color: 'red',
  },
  [AppraisalStatus.appealAnswered]: {
    name: 'Apelación respondida',
    icon: 'mdi-circle',
    color: 'primary',
  },
  [AppraisalStatus.reAppealAnswered]: {
    name: 'Re apelación respondida',
    icon: 'mdi-circle',
    color: 'purple',
  },
  [AppraisalStatus.inspectionScheduled]: {
    name: 'Inspección agendada',
    icon: 'mdi-rhombus',
    color: 'primary',
  },
  [AppraisalStatus.scheduled]: {
    name: 'Comprando',
    icon: 'mdi-circle',
    color: 'orange',
  },
  [AppraisalStatus.noOffer]: {
    name: 'Sin oferta',
    icon: 'mdi-circle',
    color: 'red',
  },
  [AppraisalStatus.reAppeal]: {
    name: 'Re apelado',
    icon: 'mdi-circle',
    color: 'purple',
  },
}

export const AppraisalStatusAlertIcons: Record<AppraisalStatusAlert, Icon> = {
  [AppraisalStatusAlert.close]: {
    tooltip: 'Cerrado',
    background: 'red',
    icon: 'mdi-car-side',
    color: undefined,
  },
  [AppraisalStatusAlert.appealAnsweredWithAgreement]: {
    icon: 'mdi-gavel',
    tooltip: 'Acuerdo de precio',
    color: undefined,
    background: 'primary',
  },
  [AppraisalStatusAlert.appraised]: {
    icon: 'mdi-gavel',
    tooltip: 'Tasado',
    color: undefined,
    background: 'green',
  },
  [AppraisalStatusAlert.expired]: {
    icon: 'mdi-gavel',
    tooltip: 'Tasación vencida',
    color: undefined,
    background: 'red',
  },
}
