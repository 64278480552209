import gql from 'graphql-tag'
import { SaleOrderFragment, StockFragment } from '@/graphql/fragments/sales'
import { FinancingFragment } from '@/graphql/fragments/loans'
import { PersonFragment } from '@/graphql/fragments/persons'

export const query = gql`
  ${SaleOrderFragment}
  ${FinancingFragment}
  ${StockFragment}
  ${PersonFragment}
  query findSaleOrder($filter: sales_sale_order_bool_exp, $order: [sales_sale_order_order_by!], $limit: Int) {
    records: sales_sale_order(where: $filter, order_by: $order, limit: $limit) {
      ...BaseSaleOrder
      closingReason: closing_reason {
        ...ClosingReason
      }
      status {
        ...ProcessStatus
      }
    }
  }
`
export default query
