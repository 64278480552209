import gql from 'graphql-tag'

export const getPersonType = gql`
  query personType {
    personType: persons_person_type(where: {name: {_neq: "system"}}) {
      name
      description
    }
  }
`

export const getPersonGender = gql`
  query getPersonGender {
    gender: persons_gender {
      name
      description
    }
  }
`

export const getMaritalStatus = gql`
  query getMaritalStatus {
    maritalStatus: persons_marital_status {
      name
      description
    }
  }
`

export const findPerson = gql`
  query findPerson($rut: String, $phone: String, $id: Int) {
    person: personInformation(rut: $rut, phone: $phone, id: $id) {
      id
      rut
      idPersonType
      idMaritalStatus
      maritalStatus
      idGender
      gender
      idNationality
      nationality
      birthDate
      age
      personalEmail
      workEmail
      personalPhone
      workPhone
      names {
        rut
        firstName
        secondName
        firstLastName
        secondLastName
        otherNames
      }
      personalLaborData {
        idLaborData
        idTypeWorker
        workerType
        idIncomeType
        incomeType
        rent
        jobTitle
        admissionDate
        timeSinceAdmission
      }
      personalBankData {
        idPersonAccount
        idBankData
        idBank
        accountNumber
        bank
        idAccountType
        accountType
      }
      personalAddressData {
        idAddress
        streetName
        streetNumber
        idCity
        cityName
        idRegion
        idPersonAddress
        regionAbbreviation
        idAddressType
        addressType
      }
      personalReferenceData {
        fullname
        generalRelation
        phone
        idPersonalReference
        idPersonReference
        idGeneralRelation
      }
    }
  }
`

export const findPersonShortInfo = gql`
  query findPerson($rut: String, $phone: String) {
    person: personInformation(rut: $rut, phone: $phone) {
      id
      rut
      idNationality
      birthDate
      age
      personalEmail
      workEmail
      personalPhone
      workPhone
      names {
        rut
        firstName
        secondName
        firstLastName
        secondLastName
        otherNames
      }
      idPersonType
    }
  }
`

export const getCountryFiltered = gql`
  query country($name: String!) {
    country(limit: 6, order_by: {name: asc}, where: {nationality: {_is_null: false}, _and: {nationality: {_ilike: $name}}}) {
      id
      name
      nationality
    }
  }
`

export const getCountryFilteredById = gql`
  query country($id: Int!) {
    country(where: { id: { _eq: $id }, nationality: { _is_null: false } }) {
      id
      name
      nationality
    }
  }
`

export const getCountry = gql`
  query country {
    country(
      limit: 6
      order_by: { national_frequency: desc }
      where: { nationality: { _is_null: false } }
    ) {
      id
      name
      nationality
      national_frequency
    }
  }
`

export const consultRut = gql`
  query consultRut($rut: String!, $personType: String! = "natural_person") {
    consultRut(rut: $rut, personType: $personType) {
      firstName
      firstLastName
      secondName
      secondLastName
      otherNames
      rut
    }
  }
`

export const generateRut = gql`
  query generateRut($rut: String!){
    generateRut(uid:$rut)
  }
`

export const findEnterprisePerson = gql`
  query findEnterprisePerson($rut: String){
    enterprise:persons_person(where: {uid: {_eq: $rut}}) {
      id
      personType:name_person_type
      companyType:company_type {
        id
        name
      }
      alias
      companyName:company_name
      rut:uid
      birthDate:birth_date
      secondaryPhone:secondary_phone
      email
    }
  }`

export const getPersonById = gql`
  query getPersonById($id: Int!) {
    person:persons_person(where: {id: {_eq: $id}}) {
      phone:main_phone
      rut: uid
    }
  }
`
