import { Type } from 'class-transformer'
import { Entity } from '..'

import { Payment, PaymentOrder, PaymentOrderItem } from '.'
import { Person } from '../persons'
import { parseToNumber } from '@/utils/general'
import { Stock } from '@/entities/sales'

export class PaymentRecipient extends Entity {
  @Type(() => Payment)
  payments: Payment[]

  @Type(() => PaymentOrder)
  paymentOrder: PaymentOrder

  @Type(() => PaymentOrderItem)
  paymentOrderItem: PaymentOrderItem

  @Type(() => Person)
  person: Person

  purchaseOrder: Record<string, any>
  expense: number | string
  stock: Stock | null
  paymentsFiltered: Payment[]

  get expenseDetails () {
    const { paymentOrderItem, expense, paymentsFiltered } = this

    let totalExpense = 0

    paymentsFiltered?.forEach(payment => {
      totalExpense += parseToNumber(payment.amount)
    })
    const amount = paymentOrderItem?.amount

    const availableAmount = parseToNumber(amount) - (parseToNumber(totalExpense) + parseToNumber(expense))
    return {
      total: amount,
      expenses: totalExpense + (parseToNumber(expense || 0)),
      available: availableAmount,
    }
  }
}
